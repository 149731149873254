import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../utils/prop-utils';
//import '../../App.css';
import i18n from '../../services/i18n';
import AuthenticationService from '../../services/Authentication';
import helper from '../../services/helper';
import InviteModal from '../Team/InviteModal';
import '../../styles/_button.scss';
import '../../styles/style.css';
import SettingsService from '../../services/SettingsService';
import ExtractService from '../../services/ExtractService';

import config from '../../config';

class DashBoard extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			fortnoxTokens: [],
			extractTasks: [],
		};
	}

	_fetchUserFortnoxTokens() {
		SettingsService.getFortnoxAccessTokens(this.props.session)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					fortnoxTokens: dataFromResponse,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}
	_fetchUserExtractTasks() {
		ExtractService.getExtractTasks(this.props.session)
			.then((dataFromResponse) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					isLoaded: true,
					extractTasks: dataFromResponse,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	componentDidMount() {
		this._isMounted = true;

		if (!this.props.session.guid) {
			if (this.getCookie('Session')) {
				AuthenticationService.fetchSession(this.getCookie('Session'))
					.then((response) => {
						this.props.setSession(response);
						this._fetchUserFortnoxTokens();
						this._fetchUserExtractTasks();
					})
					.catch((err) => {
						helper.deleteCookie('Session');
					});
			}
		} else {
			this._fetchUserFortnoxTokens();
			this._fetchUserExtractTasks();
		}
	}

	getCookie(name) {
		var value = '; ' + document.cookie;
		var parts = value.split('; ' + name + '=');
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	_renderBody() {
		return (
			<div className="container p-4">
				<div className="row gx-5 gy-5">
					<h1 className="display-4 mb-2 text-center">
						Välkommen till Redmine2Google
						{/* {i18n.t('landing_page_title')} */}
					</h1>

					<p>
						Demo av Redmine2Google för Elastic Move. Denna demo är begränsad till @elasticmove.com och @newseed.se .
						{/* {i18n.t('landing_page_text')} */}
					</p>

					<p className="mt-0">
						{i18n.t('landing_page_contact')}
						<a href="mailto:info@newseed.se?subject=Redmine adaptation">
							info@newseed.se
						</a>
						.
					</p>

					{/*<div className="col-sm-12 col-lg-4  col-xl-3">
						this.state.fortnoxTokens.length > 0 ? (
							<div className="p-3 pb-5 border gradiantbkg shadow-sm rounded h-100 w-100 d-inline-block position-relative mb-sm-2">
								<div className=" text-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="44"
										height="44"
										fill="currentColor"
										className="bi bi-cloud-download"
										viewBox="0 0 16 16"
									>
										<path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
										<path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
									</svg>
									<br></br>
									<h3 className="display-6 py-3">
										{i18n.t('instructions_company_title')}
									</h3>
								</div>
								{i18n.t('instructions_signed_in_text_part1')}(
								<b>{this.state.fortnoxTokens.length}</b>)
								{i18n.t('instructions_signed_in_company_text_part2')}
								<p>
									<a href="/settings">
										<button
											type="button"
											className="btn btn-m m-3 gradiant position-absolute bottom-0 start-0"
										>
											{i18n.t('instructions_company_title')}
										</button>
									</a>
								</p>
							</div>
						) : (
							<div className="p-3 pb-5 border gradiantbkg shadow-sm rounded h-100 w-100 d-inline-block position-relative mb-2  d-none">
								<h3 className="display-7 text-center">
									&#9312;<br></br>
									{i18n.t('instructions_not_signed_in_company_title')}
								</h3>
								{i18n.t('instructions_not_signed_in_company_text')}

								<p>
									<a href="/settings">
										<button
											type="button"
											className="btn btn-m m-3 gradiant position-absolute bottom-0 start-0"
										>
											{i18n.t('instructions_not_signed_in_company_btn')}
										</button>
									</a>
								</p>
							</div>
						)
					</div>*/}
					<div className="col-sm-12 col-lg-4  col-xl-3">
						{this.state.fortnoxTokens.length > 0 ? (
							<div className="p-3 pb-5 border gradiantbkg shadow-sm rounded h-100 w-100 d-inline-block position-relative mb-2">
								<div className=" text-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="44"
										height="44"
										fill="currentColor"
										className="bi bi-cloud-upload"
										viewBox="0 0 16 16"
									>
										<path
											fillRule="evenodd"
											d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
										/>
										<path
											fillRule="evenodd"
											d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
										/>
									</svg>
									<br></br>
									<h3 className="display-6 py-3">
										{i18n.t('instructions_extract_title')}
									</h3>
								</div>
								{i18n.t('instructions_signed_in_text_part1')}(
								<b>{this.state.extractTasks.length}</b>)
								{/* {i18n.t('instructions_signed_in_extract_text_part2')} */}
								Gå till överföringar för att se dina överföringar.
								<p>
									<a href="/extract">
										<button
											type="button"
											className="btn btn-m m-3 gradiant position-absolute bottom-0 start-0"
										>
											{i18n.t('instructions_extract_title')}
										</button>
									</a>
								</p>
							</div>
						) : (
							<div className="p-3 pb-5 border gradiantbkg shadow-sm rounded h-100 w-100 d-inline-block position-relative mb-2 d-none">
								<h3 className="display-7 text-center">
									{/* &#9313;<br></br> */}
									{i18n.t('instructions_not_signed_in_extract_title')}
								</h3>
								{i18n.t('instructions_not_signed_in_extract_text')}

								<p>
									<a href="/extract">
										<button
											type="button"
											className="btn btn-m m-3 gradiant position-absolute bottom-0 start-0"
										>
											{i18n.t('instructions_not_signed_in_extract_btn')}
										</button>
									</a>
								</p>
							</div>
						)}
					</div>
					<div className="col-sm-12  col-lg-4 col-xl-6">
						<div className="p-3 pb-0  h-100 w-100 d-inline-block position-relative mb-2">
							<img
								src={require('../../images/googleimage.jpg')}
								className="img-fluid pb-3 mx-auto d-block mt-3"
								alt="Redmine2google"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	_renderLandingPageNotSignedIn() {
		return (
			<>
				<div className="container">
					<div className="row">
						<div className="col text-center">
							<div className="mt-4 mb-4 p-3 border gradiantbkg shadow-sm rounded mw-800 d-inline-block">
								<img
									src={require('../../images/ElasticMove_logo_20191010.jpg')}
									className="img-fluid p-2 pb-0"
									alt="Elastic Move logo"
								/>
								<br></br>

								<a
									className="btn btn-lg gradiantorange big m-2"
									href={config.site.url_backend + 'authorize'}
								>
									{i18n.t('sign_in_btn')}
								</a>
								{/* 	<button type="button"  className="btn btn-lg gradiantorange big m-2">
								Logga in med Google
							</button> */}
								<p className="p-2">
									<small>
										<a
											href={config.site.url_backend + 'authorize'}
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#8ea141' }}
										>
											{i18n.t('create_account_btn')}
										</a>{' '}
										|{' '}
										<a
											href="/instructions"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#8ea141' }}
										>
											{i18n.t('sign_in_help')}
										</a>
										|{' '}
										<a
											href="https://newseed.se"
											target="_blank"
											rel="noopener noreferrer"
											style={{ color: '#8ea141' }}
										>
											{' '}
											{i18n.t('sign_in_newseed')}
										</a>
									</small>
								</p>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col text-center">
							<div className="p-3 border shadow-sm rounded d-inline-block new-user-info">
								<p className="m-0">{i18n.t('new_user_info')}</p>
							</div>
						</div>
					</div>
				</div>
				{/*
				<div className="container-xl mb-4">
					<div className="row gx-5 gy-5">
						<div className="p-3 h-100 w-100 d-inline-block position-relative mb-2">
							<img
								width="70%"
								src={require('../../images/f2g.jpg')}
								className="img-fluid pb-3 mx-auto d-block mt-3"
								alt="Fortnox2google"
							/>
						</div>
						<h1 className="display-4 mb-2 text-center">
							{i18n.t('landing_page_header_title')}
						</h1>
						<div className="col-sm-12 col-lg-4">
							<div className="p-3 pb-5 border gradiantbkg shadow-sm rounded h-100 w-100 d-inline-block position-relative mb-sm-2">
								<img
									src={require('../../images/logo-fortnox.69405291.png')}
									className="img-fluid pb-3 mx-auto d-block mt-3"
									style={{ maxHeight: 100 }}
									alt="Fortnox logo"
								/>
								<h3 className="display-5 text-center">Fortnox</h3>
								{i18n.t('landing_page_column_fortnox_text')}

								<p>
									<a
										href="https://fortnox.se"
										target="_blank"
										rel="noopener noreferrer"
									>
										{' '}
										<button
											type="button"
											className="btn btn-m m-3  position-absolute bottom-0 start-0"
										>
											Fortnox
										</button>
									</a>
								</p>
							</div>
						</div>
						<div className="col-sm-12 col-lg-4">
							<div className="p-3 pb-5 border gradiantbkg shadow-sm rounded h-100 w-100 d-inline-block position-relative mb-2">
								<img
									src={require('../../images/google.png')}
									className="img-fluid pb-3 mx-auto d-block"
									style={{ maxHeight: 100 }}
									alt="Google logo"
								/>
								<h3 className="display-5 text-center">Google Docs</h3>
								{i18n.t('landing_page_column_googledocs_text')}

								<p>
									<a
										href="https://docs.google.com/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<button
											type="button"
											className="btn btn-m m-3 position-absolute bottom-0 start-0"
										>
											Google Docs
										</button>
									</a>
								</p>
							</div>
						</div>
						<div className="col-sm-12 col-lg-4">
							<div className="p-3 pb-5 border gradiantbkg shadow-sm rounded h-100 w-100 d-inline-block position-relative mb-2">
								<img
									src={require('../../images/logo-newseed.a36e79b6.png')}
									className="img-fluid pb-3 mx-auto d-block"
									style={{ maxHeight: 100 }}
									alt="NewSeed Logo"
								/>
								<h3 className="display-5 text-center">
									{' '}
									{i18n.t('landing_page_column_newseed_title')}
								</h3>
								{i18n.t('landing_page_column_newseed')}

								<p>
									<a
										href="https://fortnox2google.se"
										target="_blank"
										rel="noopener noreferrer"
									>
										<button
											type="button"
											className="btn btn-m m-3  position-absolute bottom-0 start-0"
										>
											Fortnox 2 Google
										</button>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
						*/}
			</>
		);
	}

	_renderContent() {
		if (this.props.session.guid) {
			return this._renderBody();
		} else {
			return this._renderLandingPageNotSignedIn();
		}
	}
	render() {
		return (
			<div>
				{this._renderContent()} <InviteModal />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
