import React from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import i18n from '../../services/i18n';
import SettingsService from '../../services/SettingsService';
import FortnoxSettings from './FortnoxSettings';
import ExtractService from '../../services/ExtractService';
import Spinner from 'react-bootstrap/Spinner';
import { mapDispatchToProps, mapStateToProps } from '../../utils/prop-utils';

class Settings extends React.Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			settings: null,
			loading: true,
			scopes: [],
		};
	}

	componentDidMount() {
		this._isMounted = true;
		let promises = [];
		promises.push(SettingsService.getSettings(this.props.session));
		promises.push(ExtractService.getResourceScopes());

		Promise.all(promises)
			.then((res) => {
				if (!this._isMounted) {
					return;
				}
				let settings = res[0];
				let scopes = res[1];
				this.setState({
					loading: false,
					settings: settings,
					scopes: scopes,
				});
			})
			.catch((err) => {
				this.props.setGlobalErrorMessage(
					i18n.t('error_modal_connecting_to_api'),
					JSON.stringify(err),
					'error'
				);
			});
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	_renderContent() {
		if (this.state.loading) {
			return <Spinner variant="primary" animation="border" />;
		} else {
			return (
				<div className="container pt-3">
					<h3>{i18n.t('extract_label_choose_fortnox_token')}</h3>

					<div className="alert alert-secondary" role="alert">
						{i18n.t('settings_fortnox_description')}
					</div>

					<FortnoxSettings
						settings={this.state.settings}
						location={this.props.location}
						history={this.props.history}
						scopes={this.state.scopes}
					/>

					{/* <h3 style={{ marginTop: 50 }}>
						{i18n.t('settings_fortnox_buy_title')}
					</h3> */}

					{/* <div className="alert alert-secondary" role="alert">
						{i18n.t('settings_fortnox_buy_description1')}
						<a
							id="ember18"
							target="_blank"
							className="ember-view"
							href="https://www.fortnox.se/kopplingar/integrationer"
							rel="noopener noreferrer"
						>
							Fortnox app market.
						</a>
					</div> */}
				</div>
			);
		}
	}

	render() {
		return <div id="page-content-wrapper">{this._renderContent()}</div>;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
