import NetworkService from './NetworkService';

const ExtractService = {
	getExtractableResources: function (session) {
		return NetworkService.getResourceWithAuth(
			'v1/fortnox/extractable/resources',
			session
		);
	},
	getExtractableRedmineResources: function (session) {
		return NetworkService.getResourceWithAuth(
			'v1/redmine/extractable/resources',
			session
		);
	},
	getExtractableReports: function (session) {
		return NetworkService.getResourceWithAuth(
			'v1/fortnox/extractable/reports',
			session
		);
	},
	getResourceScopes: function () {
		return NetworkService.getResource('v1/fortnox/scopes');
	},
	createExtractTask: function (obj, session) {
		return NetworkService.postResourceWithAuth('v1/extract', obj, session);
	},
	updateExtractTask: function (id, obj, session) {
		return NetworkService.putResourceWithAuth('v1/extract/' + id, obj, session);
	},
	stopExtraction: function (id, session) {
		return NetworkService.putNoDataResourceWithAuth(
			`v1/extract/${id}/stop`,
			session
		);
	},
	deleteExtractTask: function (id, alsoDeleteFromDrive, session) {
		return NetworkService.deleteResourceWithAuth(
			'v1/extract/' + id + '/' + (alsoDeleteFromDrive ? 'true' : 'false'),
			session
		);
	},
	getExtractTasks: function (session) {
		return NetworkService.getResourceWithAuth('v1/extract', session);
	},
	getTasksLeft: function (userid, session) {
		return NetworkService.getResourceWithAuth(
			'v1/user/tasksleft/' + userid,
			session
		);
	},
	runTaskManually: function (id, session) {
		return NetworkService.postResourceWithAuth(
			'v1/extract/run/' + id,
			{},
			session
		);
	},
	autoUpdateToken: function (tokenid, session) {
		return NetworkService.putNoDataResourceWithAuth(
			'v1/autoupdatetoken/' + tokenid,
			session
		);
	},

	createTaskParams: function (taskid, obj, session) {
		return NetworkService.postResourceWithAuth(
			'v1/extract/createtaskparams/' + taskid,
			obj,
			session
		);
	},
	updateTaskParams: function (taskid, obj, session) {
		return NetworkService.putResourceWithAuth(
			'v1/extract/updatetaskparams/' + taskid,
			obj,
			session
		);
	},
	getTaskParamsFinancialYears: function (session) {
		return NetworkService.getResourceWithAuth(
			'v1/taskparamsfinancialyears',
			session
		);
	},

	getTaskParamsFrontend: function (taskid, session) {
		return NetworkService.getResourceWithAuth(
			'v1/taskparamsfrontend/' + taskid,
			session
		);
	},
};
export default ExtractService;
